import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="fx-footer-navigation">
      <div className="menu-navigation">
        <nav
          id="ubermenu-footer-811"
          className="ubermenu ubermenu-nojs ubermenu-footer ubermenu-menu-811 ubermenu-responsive ubermenu-responsive-1024 ubermenu-responsive-nocollapse ubermenu-horizontal ubermenu-transition-shift ubermenu-trigger-hover_intent ubermenu-skin-minimal ubermenu-bar-align-full ubermenu-items-align-auto ubermenu-bound ubermenu-disable-submenu-scroll ubermenu-sub-indicators ubermenu-retractors-responsive ubermenu-submenu-indicator-closes"
        >
          <ul
            id="ubermenu-nav-footer-811"
            className="ubermenu-nav"
            data-title="Footer Menu"
          >
            <li className="ubermenu-item ubermenu-item-type-custom ubermenu-item-object-ubermenu-custom ubermenu-item-has-children ubermenu-item-290507 ubermenu-item-level-0 ubermenu-column ubermenu-column-auto ubermenu-has-submenu-drop ubermenu-has-submenu-mega ubermenu-item-type-column ubermenu-column-id-290507">
              <ul className="ubermenu-submenu ubermenu-submenu-id-290507 ubermenu-submenu-type-stack">
                <li
                  id="menu-item-290510"
                  className="ubermenu-item ubermenu-item-type-custom ubermenu-item-object-ubermenu-custom ubermenu-item-290510 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-1 ubermenu-column ubermenu-column-full"
                >
                  <ul className="ubermenu-content-block ubermenu-widget-area ubermenu-autoclear">
                    <li
                      id="block-24"
                      className="widget widget_block ubermenu-column-full ubermenu-widget ubermenu-column ubermenu-item-header"
                    >
                      <div className="company-info">
                        <img
                          loading="lazy"
                          width={228}
                          height={59}
                          src="/head/webfx-logo.png"
                          alt="WebFX Logo"
                        />
                        <span className="tag-line">
                          Celebrating 25+ Years of Digital Marketing Excellence
                        </span>
                        <p>
                          Ready to speak with a marketing expert? Give us a ring
                        </p>
                        <a
                          href="tel:8886015359"
                          className="fx-btn fx-primary-btn-light fx-btn-large"
                        >
                          888-601-5359
                        </a>
                      </div>
                      <p />
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li
              id="menu-item-290522"
              className="ubermenu-item ubermenu-item-type-custom ubermenu-item-object-custom ubermenu-item-has-children ubermenu-advanced-sub ubermenu-item-290522 ubermenu-item-level-0 ubermenu-column ubermenu-column-auto ubermenu-has-submenu-drop ubermenu-has-submenu-mega"
            >
              <span
                className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                tabIndex={0}
              >
                <span className="ubermenu-target-title ubermenu-target-text">
                  Company
                </span>
                <i className="ubermenu-sub-indicator fas fa-angle-down" />
              </span>
              <div className="ubermenu-submenu ubermenu-submenu-id-290522 ubermenu-submenu-type-auto ubermenu-submenu-type-mega ubermenu-submenu-drop ubermenu-submenu-align-full_width">
                <ul className="ubermenu-row ubermenu-row-id-290527 ubermenu-autoclear">
                  <li className="ubermenu-item ubermenu-item-type-custom ubermenu-item-object-ubermenu-custom ubermenu-item-has-children ubermenu-item-290529 ubermenu-item-level-2 ubermenu-column ubermenu-column-1-12 ubermenu-has-submenu-stack ubermenu-item-type-column ubermenu-column-id-290529">
                    <ul className="ubermenu-submenu ubermenu-submenu-id-290529 ubermenu-submenu-type-stack">
                      <li
                        id="menu-item-290556"
                        className="ubermenu-item ubermenu-item-type-custom ubermenu-item-object-custom ubermenu-item-290556 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-3 ubermenu-column ubermenu-column-auto"
                      >
                        <a
                          className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                          title="Get to know WebFX"
                        >
                          <span className="ubermenu-target-title ubermenu-target-text">
                            <Link to="/about" onClick={toTop}>
                              About Us
                            </Link>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-290557"
                        className="ubermenu-item ubermenu-item-type-custom ubermenu-item-object-custom ubermenu-item-290557 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-3 ubermenu-column ubermenu-column-auto"
                      >
                        <a
                          className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                          title="Explore open positions"
                        >
                          <span className="ubermenu-target-title ubermenu-target-text">
                            <Link to="/careers" onClick={toTop}>
                              Careers
                            </Link>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-290558"
                        className="ubermenu-item ubermenu-item-type-custom ubermenu-item-object-custom ubermenu-item-290558 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-3 ubermenu-column ubermenu-column-auto"
                      >
                        <a
                          className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                          title="Explore our community impact"
                        >
                          <span className="ubermenu-target-title ubermenu-target-text">
                            <Link to="/community" onClick={toTop}>
                              Community Impact
                            </Link>
                          </span>
                        </a>
                      </li>
                      <li
                        id="menu-item-290560"
                        className="ubermenu-item ubermenu-item-type-custom ubermenu-item-object-custom ubermenu-item-290560 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-3 ubermenu-column ubermenu-column-auto"
                      >
                        <a
                          className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                          title="Learn how to contact us"
                        >
                          <span className="ubermenu-target-title ubermenu-target-text">
                            <Link to="/contact" onClick={toTop}>
                              Contact Us
                            </Link>
                          </span>
                        </a>
                      </li>

                      <li
                        id="menu-item-290563"
                        className="ubermenu-item ubermenu-item-type-custom ubermenu-item-object-custom ubermenu-item-290563 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-3 ubermenu-column ubermenu-column-auto"
                      >
                        <a
                          className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                          title="Get informed about an active phishing scam"
                        >
                          <span className="ubermenu-target-title ubermenu-target-text">
                            <Link to="/phishing" onClick={toTop}>
                              Phishing Scam Alert
                            </Link>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>
        {/* End UberMenu */}
      </div>
    </div>
  );
}

export default Footer;
