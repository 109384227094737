import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footerb() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="fx-footer-bottom">
      <div className="company-info">
        <img
          loading="lazy"
          width={228}
          height={59}
          src="/head/webfx-logo.png"
          alt="WebFX Logo"
        />
        <span className="tag-line">
          Celebrating 25+ Years of Digital Marketing Excellence
        </span>
        <p>Ready to speak with a marketing expert? Give us a ring</p>
        <a
          href="tel:8886015359"
          className="fx-btn fx-primary-btn-light fx-btn-large"
        >
          888-601-5359
        </a>
      </div>
      <div className="footer-info-wrapper">
        <div className="sister-sites-wrapper">
          <h5>Proudly brought to you by WebFX</h5>
          <ul className="logos">
            <li>
              <a referrerPolicy="unsafe-url" href="https://www.seo.com/">
                <img
                  loading="lazy"
                  width={165}
                  height={65}
                  src="https://www.webfx.com/wp-content/uploads/2023/11/seo-com-logo.png"
                  className="attachment-full size-full"
                  alt="Logo of SEO.com with the 'SEO' in bold white letters and '.com' in purple."
                />
              </a>
            </li>
            <li>
              <a href="https://teamai.com/">
                <img
                  loading="lazy"
                  width={195}
                  height={64}
                  src="https://www.webfx.com/wp-content/uploads/2024/07/TeamAI-Stadard.png"
                  className="attachment-full size-full"
                  alt="TeamAI Stadard"
                />
              </a>
            </li>
            <li>
              <a href="https://www.nutshell.com/">
                <img
                  loading="lazy"
                  width={253}
                  height={39}
                  src="https://www.webfx.com/wp-content/uploads/2023/11/nutshell-logo-white-1.png"
                  className="attachment-full size-full"
                  alt="Nutshell logo in white with an orange acorn and grey background"
                />
              </a>
            </li>
          </ul>
        </div>
        <div className="links-list-wrapper">
          <div className="company-tag-line-wrapper">
            <span className="company-tag-line">
              <strong className="company">WebFX® 1995-2024</strong>
            </span>
          </div>
          <ul className="links-list">
            <li>
              <a href="/">Sitemap</a>
            </li>
            <li>
              <Link to="/privacy" onClick={toTop}>
                Privacy &amp; Terms of Use
              </Link>
            </li>
          </ul>
          <ul className="social-media">
            <li>
              <a href="/">
                <img loading="lazy" alt="Facebook" src="/head/facebook.png" />
              </a>
            </li>
            <li>
              <a href="/">
                <img loading="lazy" alt="Twitter" src="/head/x.png" />
              </a>
            </li>
            <li>
              <a href="/">
                <img loading="lazy" alt="Linked In" src="/head/linkedin.png" />
              </a>
            </li>
            <li>
              <a href="/">
                <img loading="lazy" alt="Youtube" src="/head/youtube.png" />
              </a>
            </li>
            <li>
              <a href="/">
                <img loading="lazy" alt="Instagram" src="/head/instagram.png" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footerb;
