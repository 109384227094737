import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/header.css";

function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [fixed, setFixed] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    setScrolled(scrollY > 1);
    setFixed(scrollY > 72);
  };
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header id="page-header" className="page-header">
      <div id="fxtopbar">
        <div className="container-fluid">
          <div className="fxtopbar-inner">
            <a
              className="fxtopbar-revenue"
              href="https://www.webfx.com/about/results/"
            >
              <h5 className="_title">REVENUE DRIVEN FOR OUR CLIENTS</h5>
              <div className="_value">$10,085,355,239+</div>
            </a>
            <div className="fxtopbar-right">
              <ul className="fxtopbar-menu">
                <li className="_phone">
                  <a href="tel:8886015359">(415) 425-9345</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="fxheader">
        <div className="container-fluid">
          <div id="fxmenu">
            <div className="_logo">
              <a href="https://www.webfx.com" className="_logo-img">
                <img
                  loading="lazy"
                  width={127}
                  height={32}
                  src="https://www.webfx.com/wp-content/uploads/2023/05/webfx-logo.svg"
                  className="logo"
                  alt="WebFX Logo"
                />
              </a>
              <div className="_logo-desc">
                Digital Marketing
                <br />
                That Drives Revenue®
              </div>
            </div>
            <a href="#" className="_menu-toggle" aria-label="Menu Toggle">
              <span className="burger">
                <span />
                <span />
                <span />
              </span>
            </a>
            <div className="_menu">
              <div className="desktop-nav-primary" />
              <div className="_menu-footer-mobile">
                <a
                  href="#"
                  className="_btn search-link js-search-toggle js-no-smooth-anchor"
                >
                  <i className="ichdr-search" />
                  Search
                </a>
                <a href="https://app.webfx.com/" className="_btn login-link">
                  Client Login
                </a>
              </div>
            </div>
            <div className="fxtopbar-menu">
              <ul>
                <li>
                  <Link to="/" onClick={toTop}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" onClick={toTop}>
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/privacy" onClick={toTop}>
                    Privacy
                  </Link>
                </li>
                <li>
                  <Link to="/contact" onClick={toTop}>
                    Contact
                  </Link>
                </li>
              </ul>
            </div>

            <Link to="/" className="_get_proposal_btn">
              Get a Proposal
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
