import React from "react";
import Footer from "./Footer";
import "../css/shop.css";
import { Link } from "react-router-dom";
import productData from "../Datas/productData";

function Shop() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <div className="header__inner__left">
        <img
          width="150"
          height="64"
          alt=""
          src="https://neveragain.allstatics.com/2019/assets/icon/logo/wondershare-vertical-white.svg"
        />
        <div className="head_img_right_title">
          <ul>
            <li>
              <Link to="/" onClick={toTop}>
                Video Creativity
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toTop}>
                Diagram & Graphics
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toTop}>
                PDF Solutions
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toTop}>
                Data Management
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toTop}>
                Explore AI
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toTop}>
                Business
              </Link>
            </li>

            <li>
              <Link to="/shop" onClick={toTop}>
                Shop
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={toTop}>
                Support
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="shop_box">
        <div className="shop_box_inner_top">
          <h1>Plans & Pricing</h1>
          <div className="shop_box_inner_bot">
            <div className="shop_box_inner_bot_inner">Individuals</div>
            <div className="shop_box_inner_bot_inner">Team & Business</div>
            <div className="shop_box_inner_bot_inner">Education</div>
          </div>
        </div>

        <div className="product_box_all">
          <h5>All Products</h5>
          <div className="goods_box_div">
            {productData.map((ai, index) => (
              <div className="goods_box_div_inner" key={index}>
                <div className="good_img">
                  <img width="145" height="40" alt="" src={ai.images} />
                </div>
                <p className="item_details">{ai.details}</p>
                <div class="font-size-large-pb-3">
                  <big class="price">${ai.price}</big>/
                  <span class="period">year</span>
                </div>
                <div className="buy_now">
                  <Link to={`/checkout/${ai.id}`} onClick={toTop}>
                    Buy Now
                  </Link>
                </div>
                <div className="works_div">
                  <span class="mr-1">Works on: </span>
                  <ul>
                    {ai.works.map((work, index) => (
                      <li key={index} className="work_img">
                        <img src={work} alt="" width="20" height="20" />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Shop;
